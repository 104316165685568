/* GRAVITY FORMS */
/* .gform_wrapper{
  & li{
    margin-bottom: 1.5rem;
    width: 100%;
    float: left;
    position: relative;
    // 50% - 50% -> ADD this classes on gravity forms
    &.w-50.float-left{
      padding-right: 1rem;
    }
    &.w-50.float-right{
      padding-left: 1rem;
    }
    
    & > .ginput_complex {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -0.75rem;
      & > span{
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 0.75rem;
      }
    }
    & label{
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 1.2;
      color: $primary-color;
      text-transform: uppercase;
      display: block;
      margin-bottom: 0.5rem!important;
      text-align: left;
    }
    & span.gfield_required{
      color: $error-color;
      padding-left: 2px;
    }
    & textarea,
    & input{
      width: 100%;
      background: $white;
      border: 1px solid #CDDAE3;
      box-sizing: border-box;
      padding: 0 1.5rem;

      font-style: normal;
      font-weight: normal; 
      font-size: 16px;
      line-height: 1.62;
      color: $black;
      &::placeholder {
        color: rgba($black, 0.6);
      }
    }
    & input{
      height: 56px;
    }
    & textarea{
      height: 168px;
      padding: 1rem 1.5rem;
    }
    & .ginput_container_consent{
      position: relative;
      & label{
        margin: 0!important;
        padding-left: 2rem;
        font-style: normal;
        font-weight: normal;
        text-transform: initial;
        font-size: 15px;
        line-height: 1.33;
        color: $dark-gray;
        &:before {
          content: "";
          position: absolute;
          left: 0px;
          width: 20px;
          height: 20px;
          border: 1px solid #CDDAE3;
          margin-right: 10px;
        }
        &:after {
          content: "";
          position: absolute;
          left: 3px;
          top: 5px;
          width: 14px;
          height: 11px;
          background: url('../images/tick.svg') no-repeat 50%;
          background-size: contain;
          opacity: 0;
          visibility: hidden;
          transition: all .4s ease-in;
        }
      }
      & input{
        position: absolute;
        opacity: 0;
        z-index: 10;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
      }
      & input:checked ~ label  {
        &:after{
          opacity: 1;
          visibility: visible;
        }
      }
      & .gfield_required{
        display: none;
      }
    }
  }
  & li.hidden_label{
    & > label{
      display: none;
    }
  }
  & .gform_footer{
    & input.gform_button{
      //BUTTON HERE
    }
  }

  //error and nots
  &.gform_validation_error{
    & li {
      padding-bottom: 1.2rem;
    }
  }
  & .validation_error{
    display: none;
  }
  & .validation_message{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    color: $error-color;
    text-transform: initial;
    display: block;
    text-align: left;
    padding-left: 0.25rem;
    width: 100%;
    position: absolute;
  }
  & li.gfield_error {
    & label::before{
      border-color: $error-color;
    }
  }

  @media only screen and (max-width: $breakpoint-md - 1) {	
    & li > .ginput_complex {
      margin-bottom: -1.5rem;
      & > span{
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 1.5rem;
      }
    }
    & li.w-50{
      width: 100%!important;
    }
  }

} */

.ginput_container_checkbox{
  position: relative;
  & label{
    margin: 0!important;
    padding-left: 2rem;
    font-style: normal;
    font-weight: normal;
    text-transform: initial;
    font-size: 15px;
    line-height: 1.33;
    color: $dark-gray;
    &:before {
      content: "";
      position: absolute;
      left: 0px;
      width: 20px;
      height: 20px;
      border: 1px solid #000;
      margin-right: 10px;
      border-radius: 6px;
    }
    &:after {
      content: "";
      position: absolute;
      left: 3px;
      top: 8px;
      width: 14px;
      height: 11px;
      background: url('../images/tick.svg') no-repeat 50%;
      background-size: contain;
      opacity: 0;
      visibility: hidden;
      transition: all .4s ease-in;
    }
  }
  & input{
    position: absolute;
    opacity: 0;
    z-index: 10;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
  }
  & input:checked ~ label  {
    &:after{
      opacity: 1;
      visibility: visible;
    }
  }
  & .gfield_required{
    display: none;
  }
}

.gform_wrapper {
  .gform_fields {
    .gfield {
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 10px;
        left: 15px;
        width: 20px;
        height: 20px;
      }
      &.icon_man {
        &:before {
          background: url(../images/form_man.svg);
        }
      }
      &.icon_envelope {
        &:before {
          background: url(../images/form_mail.svg);
        }
      }
      &.icon_briefcase {
        &:before {
          background: url(../images/form_briefcase.svg);
        }
      }
      &.icon_link {
        &:before {
          background: url(../images/form_link.svg);
        }
      }

      .ginput_container {
        input[type="text"],
        input[type="email"],
        input[type="url"] {
         padding-left: 0;
         background: transparent;
         border: none;
         border-bottom: 1px solid #000;
       }
     }
   }
 }
}

.gform_wrapper.gravity-theme .gform_fields {
  grid-row-gap: 48px !important;
}

.gform_wrapper .gform_validation_errors,
.gform_wrapper .gfield_validation_message, .gform_wrapper .validation_message {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  margin-top: .5rem !important;
  padding-top: 0 !important;
  font-size: 12px;
}

h2.gform_submission_error.hide_summary {
  display: none !important;
}

h2.gform_title {
  display: none;
}

.gform_wrapper .gfield_validation_message, .gform_wrapper .validation_message {
  margin-top: 8px;
  padding: 0;
}

span#input_4_1_3_container {
  margin-bottom: 0;
}

.gform_wrapper .gfield_validation_message, .gform_wrapper .validation_message {
  padding: 0 !important;
}

.gchoice label,
.gchoice label a {
  font-family: Noto Sans !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #000 !important;
}

.gchoice label a {
  text-decoration: underline !important;
}

span.gfield_required.gfield_required_text {
    display: none;
}



