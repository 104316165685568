header {
  position: absolute;
  background: transparent;
  z-index: 10;
  width: 100%;
  padding: 28px 0;
  width: 100%;
  z-index: 100;
  &.scroll {
    position: fixed;
    background: $dark-gray;
    top: 0;
    left: 0;
  }

  .all-wrapper {
    width: 100%;
    text-align: center;
    .nav-primary {
      .nav {
        display: inline-flex;

        li {
          &.only-footer {
            display: none;
          }
            
          a {
            font-family: 'Bebas Neue', cursive;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 19px;
            letter-spacing: 0.05em;
            padding: 0 16px;
            color: $yellow;
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
    .text-right {
      text-align: right;
    }
  }
}

/* Header Mobile */
header.mob {
  transition: .3s;
  height: 76px;
  padding: 0;

  &.mobile {
    box-shadow: none;

    .menu-mob {
      margin-top: -1rem;
      //display: none;
    }

    &.scroll {
      border-radius: 0px 0px 8px 8px;
      background: $dark-gray;

      .menu-mob {
        display: block;
      }
    }
  }
  .logo-wrapper {
    position: relative;
    top: 8px;
    left: 16px;
  }
}

/* MENU BURGER */
.burger-container {
  z-index: 100000;
  position: relative;
  height: 50px;
  width: 50px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 10;
  position: absolute;
  right: 16px;
  top: 12px;

  & #burger {
    width: 34px;
    height: 25px;
    position: relative;
    display: block;
    margin: 2px auto 0;
    top: 50%;
    transform: translateY(-50%);

    & .bar {
      width: 100%;
      height: 2px;
      display: block;
      position: relative;
      background: $yellow;
      transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
      transition-delay: 0s;
    }

    & .bar.topBar {
      transform: translateY(0px) rotate(0deg);

      &:after {
        display: block;
        content: "";
        position: absolute;
        top: 9px;
        left: 0;
        width: 34px;
        height: 2px;
        background: $yellow;
      }
    }

    & .bar.btmBar {
      transform: translateY(16px) rotate(0deg);
    }
  }
}

.header.menu-opened {
  z-index: 114;

  & .burger-container {
    transform: rotate(90deg);

    & #burger {
      & .bar {
        transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
        transition-delay: 0.2s;
      }

      & .bar.topBar {
        transform: translateY(6px) rotate(45deg);

        &:after {
          display: none;
        }
      }

      & .bar.btmBar {
        transform: translateY(3px) rotate(-45deg);
      }
    }
  }
}

nav.nav-menu-mobile {
  overflow: hidden;
}

a.button.gradient.white-bg {
  background: $white;
  color: $green-btn;
  box-shadow: 0px 2px 30px rgba(48, 70, 89, 0.15);
  color: $blue;
  width: 100%;
  padding: 0.75rem 0;

  &.top {
    background: $white;
    color: $green-btn;
    padding: .5rem 1.5rem;
    display: none;
  }
}

.scroll {
  & a.button.gradient.white-bg {
    &.top {
      display: block;
    }
  }
}

#menu-full {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0px;
  z-index: 113;
  //display: none;
  background-color: $dark-gray;
  display: none;

  //opacity: 0;
  transform: translateY(-120vh);
  //transition: transform 0.7s cubic-bezier(0.455, 0.03, 1, 0.545) 0s;
  //transition: all .7s ease-in-out;
  //overflow: scroll;

  /*&::before{
    content: '';
    position: fixed;
    width: 100%;
    height: 125px;
    top: 0;
    left: 0;
    background: $gradient;
    z-index: 1;
  }*/
  & .move-content {
    width: 100vw;
    height: 100vh;

    &.scroll {
      overflow-y: auto;
      z-index: 0;
      overflow: hidden;
    }
  }

  &.opened {
    display: block;
    transform: translateY(0);

    & .main-menu-full {
      $a: 10;

      @for $i from 1 through $a {
        & li:nth-child(#{$i}) {
          transition: all 0.6s ease #{$i * 0.1 + 0.5}s;
          opacity: 1;
          transform: translateX(0);
        }
      }

      & .btn-div {
        transition: all 0.6s ease 1.1s;
        opacity: 1;
        transform: translateX(0);
      }
    }

    & .main-menu-info {
      transition: all 0.6s ease 1.2s;
      opacity: 1;
      transform: translateX(0);
    }
  }

  & .main-menu-full {
    padding: 145px 2rem 0px;
    overflow: hidden;

    & ul {
      padding-left: 1rem;
      display: block;

      & li {
        opacity: 0;
        transform: translateX(-50px);
        margin-bottom: 0.5rem;

        & a {
          font-family: $secondary-font;
          font-size: 24px;
          font-weight: 900;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: normal;
          color: $white;

          &:hover {
            color: $blue;
          }
        }
      }
    }

    & .btn-div {
      padding-top: 32px;
      padding-left: 0;
      padding-right: 0;
      opacity: 0;
      transform: translateX(-50px);

      & a.button.glow-border::after {
        background: $black;
      }
    }
  }

  & .main-menu-info {
    position: absolute;
    right: 1rem;
    bottom: 5rem;

    opacity: 0;
    transform: translateX(50px);

    & .vitamina,
    & .vitamina a {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: $white;
    }
  }

  & a.button.gradient.white-bg {
    background: $white;
    box-shadow: 0px 2px 30px rgba(48, 70, 89, 0.15);
    color: $blue;
    width: 100%;
    padding: 0.75rem 0;
    color: $green-btn;

    &.top {
      background: $white;
      color: #31BFA4;
    }
  }
}

#menu-bg {
  //opacity: 0;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 112;
  transform: translateY(-120vh);

  &.bg-1 {
    background: $dark-gray;
    //transition: transform 0.3s cubic-bezier(0.455, 0.03, 1, 0.545) 0s;
    transition: all .3s ease-in-out;
  }

  &.bg-2 {
    background: #0f1936 none repeat scroll 0% 0%;
    //transition: transform 0.5s cubic-bezier(0.455, 0.03, 1, 0.545) 0s;
    transition: all .5s ease-in-out;
  }

  &.opened {
    //opacity: 1;
    transform: translateY(0px);
  }
}