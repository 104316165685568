/*  FONTS, VARIABLES */

/* :::::: Fonts :::::: */
$primary-font: 'Barlow', sans-serif;
$secondary-font: 'Barlow', sans-serif;

/* :::::: Colors :::::: ; */
$primary-color: #344abd !default;
$secondary-color: #00f6cb !default;
$error-color: #c00 !default;

$white: #ffffff !default;
$black: #000000 !default;
$blue: #003898;
$blue-button: #065ab2;
$gradient: linear-gradient(90deg, #1BA2CB 0%, #31BFA4 100%);
$grey: #31BFA5;
$bright: #F5F9FC;
$primary-600: #065AB2;
$primary-200: #34B9D8;
$neutral: #2E3E56;
$neutral-200: #EDEFF3;
$neutral-500: #98ABC8;
$neutral-600: #6E8CB4;
$primary-300: #03A1CD;
$neutral-700: #4F6992;
$neutral-800: #2E3E56;
$primary-800: #003898;
$green-btn: #31BFA4;

/* ABC */
$yellow: #EAC900;

$light-gray: #F6F6F6 !default;
$medium-gray: #eeeeee !default;
$dark-gray: #222222 !default;

//colors up here, are obrigatory!
//more colores if make sence


/* :::::  Spacing :::::: */

/* Between Elements */
$sp8: 8px;
$sp12: 12px;
$sp16: 16px;
$sp18: 18px;
$sp20: 20px;
$sp24: 24px;
$sp36: 36px;
$sp40: 40px;
$sp48: 48px;
$sp56: 56px;
/* Between Elements */

/* Between Blocks */
$sp32: 32px;
$sp64: 64px;
$sp84: 84px;
$sp92: 92px;
$sp112: 112px;
$sp140: 140px;
$sp184: 184px;
$sp192: 192px;
/* Between Blocks */


/* :::::: Styling Classes :::::: */
.black {
  color: $black;
}

.white {
  color: $white;
}

.primary-color {
  color: $primary-color;
}

.secondary-color {
  color: $secondary-color;
}

.primary-bg {
  background-color: $primary-color;
}

.secondary-bg {
  background-color: $secondary-color;
}

/* ::::::  Font :::::: */
body {
  font-family: $primary-font;
}

/* :::::: Predefined Color :::::: */


span,
p {
  color: $black;
}


/* :::::  Typo :::::: */
h1,
h1 p,
h2,
h2 p {
  text-transform: uppercase;
  font-family: $secondary-font;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 46px;
  line-height: 55px;
  text-align: center;
  letter-spacing: 0.01em;
}

h2.subtitle,
h2.subtitle p {
  font-style: normal;
  font-size: 17px;
  line-height: 25px;
}

h3,
h3 p {
  font-family: $secondary-font;
  font-size: 45px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11; /*50px*/
  letter-spacing: normal;
  color: $black;
  letter-spacing: 0.01em;
}

h4,
h4 p {
  font-family: $secondary-font;
  font-size: 35px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14; /*40px*/
  letter-spacing: normal;
  color: $black;
}

h5,
h5 p {
  font-family: $primary-font;
  font-size: 25px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2; /*30px*/
  letter-spacing: normal;
  /*text-transform: uppercase;*/
}

span,
p {
  font-family: $primary-font;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}


/* ::::::  VARIABLES :::::: */
//use var(--container-width);
@media only screen and (min-width: 576px) {
  :root {
    --container-width: 540px;
  }
}

@media only screen and (min-width: 768px) {
  :root {
    --container-width: 720px;
  }
}

@media only screen and (min-width: 1024px) {
  :root {
    --container-width: 992px;
  }
}

@media only screen and (min-width: 1280px) {
  :root {
    --container-width: 1148px;
  }
}

// @media only screen and (min-width: 1440px) {
// }
