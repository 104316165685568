footer {
	background: $dark-gray;
	padding: 68px;
	.all-wrapper {
		width: 100%;
		text-align: center;
		.nav-primary {
			.nav {
				display: inline-flex;
				a {
					font-family: 'Bebas Neue', cursive;
					font-style: normal;
					font-weight: normal;
					font-size: 15px;
					line-height: 19px;
					letter-spacing: 0.05em;
					padding: 0 12px;
					color: $yellow;
					&:hover {
						color: $white;
					}
				}
			}
		}
		.vita-wrapper {
			span,
			span a {
				font-family: $primary-font;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 19px;
				text-align: right;
				color: #FFFFFF;
			}
			span a {
				&:hover {
					color: $yellow;
				}
			}
		}
	}
}