@media only screen and (max-width: $breakpoint-lg - 1) {
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 38px;
  }

  h2 {
    font-size: 36px;
    line-height: 38px;
    text-align: center;
  }

  h4 {
    font-size: 32px;
    line-height: 38px;
  }

  span,
  p {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    display: block;
  }

  .divider.text-center svg {
    width: 100%;
  }

  header {
    nav,
    .socio-wrapper {
      display: none;
    }
    .logo-wrapper {
      text-align: left;
      svg {
        max-height: 36px;
        left: 0px;
        position: absolute;
      }
    }
  }

  .background-mobile {
    margin-top: 0px;
    img {
      position: relative;
      z-index: 1;
      top: 0;
      width: 100%;
    }
  }

  a.button.social {
    padding: 10px 20px;
    height: 40px;
    border-radius: 20px;
  }

  section.banner {
    height: 100%;
    padding: 56px 0 0 !important;
    .next-matches {
      text-align: center;
      position: relative;
      h2 {
        font-size: 36px;
        line-height: 38px;
        text-align: center;
        display: block;
        margin-top: 36px;
      }
      span {
        margin: 12px 0 30px;
      }
    }
  }

  section.faz-parte {
    padding: 56px 0 !important;

    .background img {
      object-position: left;
    }

    span.desc {
      margin-bottom: 32px;
    }
  }

  section.palmares {
    padding: 56px 0 !important;

    h2 {
      color: $yellow;
    }
  }

  section.equipas {
    padding: 56px 0 !important;
  }

  section.apoiar {
    padding: 56px 0 0 !important;
    padding-bottom: 0 !important;
    border: none;
    .swiper-horizontal {
      padding-bottom: 56px;
    }
    .card-wrapper {
      margin-bottom: 32px;

      .image.wrapper {
        margin-bottom: 32px;
      }
      span.position {
        font-size: 14px;
      }
    }
    h2 {
      margin-bottom: 32px;
    }
  }

  section.faz-parte.form .left-wrapper h2,
  section.faz-parte.form .left-wrapper span {
    text-align: center;
  }

  section.faz-parte.form {
    padding-bottom: 0 !important;
    .no-margin {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
    .form-wrapper {
      //border-radius: 0;
      background: #eac900;
      margin-top: 0px;
      //padding: 56px 16px 28px;
      margin-right: 0px;
    }
  }

  section.newsletter {
    padding: 56px 0 !important;

    span.address {
      text-align: left;
      margin-bottom: 56px;
    }
  }

  section.patrocinadores {
    padding: 56px 0 !important;
  }

  footer {
    padding: 56px 0;
    .logo-wrapper {
      text-align: left;
      svg {
        max-height: 48px;
        margin-left: 0px;
      }
    }
    .all-wrapper {
      .vita-wrapper span,
      .vita-wrapper span a {
        text-align: left;
      }
      .nav-primary .nav {
        display: block;
        text-align: left;
        margin: 36px 0;
      }
      .nav-primary .nav a {
        padding: 16px 0;
        display: block;
      }
    }
  }
  /* Menu */
  .header.mob {
    display: block;
  }

  section.faz-parte.form .left-wrapper h2,
  section.faz-parte.form .left-wrapper span {
    text-align: left;
  }

  #menu-full .main-menu-full ul li a {
    color: $yellow;
  }

  /*ection.equipas .image-wrapper img {
	width: auto;
}*/

  /*End*/
}

@media screen and (device-aspect-ratio: 2/3) {
}

@media screen and (device-aspect-ratio: 40/71) {
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  /* End */
}

/* @media only screen and (max-width: $breakpoint-md - 1) {	
} */

/* @media only screen and (max-width: $breakpoint-sm - 1) {
} */
