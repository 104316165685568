/* Banner */
section.banner {
  background: #222222;
  width: 100%;
  height: 100vh;
  padding: 0;
  position: relative;

  .background {
    img {
      /*position: absolute;*/
      width: 100%;
      /*height: calc(100% - 184px);*/
      height: auto;
      z-index: 0;
      object-position: top;
      object-fit: contain;
    }
  }

  .next-matches {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 10;
    padding-bottom: 56px;

    h2 {
      text-align: left;
      color: $yellow;
    }

    span {
      color: $white;
    }

    ul.buttons {
      padding-left: 0;
      display: inline-flex;

      li {
        margin-right: 24px;
      }
    }
  }

  a.button.social {
    display: inline-flex;
    align-items: end;

    svg {
      margin-right: 4px;
    }
  }
}


/* Faz Parte */
section.faz-parte {
  padding: 112px 0;

  .background {
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
      top: 0;
    }
  }

  h1 {
    color: $black;
  }

  span {
    display: block;
    margin: 12px 0 32px;
  }

  .ginput_container_name span {
    margin: 0;
  }
}

/* Palmarés */
section.palmares {
  padding: 112px 0;

  .background {
    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }

  h2,
  span {
    color: $white;
  }

  h3 {
    color: $yellow;
  }

  img.palmares {
    margin-top: 54px;
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  object {
    margin-top: 54px;
  }

  /*.line-wrapper {
		display: flex;
		align-items: center;
		span,
		span p {
			color: $white;
		}
		span.number {
			font-family: $secondary-font;
			font-weight: 900;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			font-size: 45px;
		}
		span.text {
			font-family: $secondary-font;
			font-weight: 900;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: normal;
			font-size: 18px;
		}
	}*/
}

section.faz-parte.form {
  padding: 0;
  width: 100%;

  .row {
    padding-left: 0;
    padding-right: 0;
    /*margin-left: 0;
		margin-right: 0;*/
  }

  .background {
    img {
      height: calc(100% - 28px);
    }
  }

  .left-wrapper {
    margin-bottom: 56px;

    h2,
    span {
      text-align: left;
    }
  }

  .form-wrapper {
    border-radius: 20px;
    background: $yellow;
    margin-top: -36px;
    padding: 56px;
    margin-right: -15px;

    a.button,
    input#gform_submit_button_4,
    input#gform_submit_button_5 {
      background: $black;
      color: $white;
    }

    span#input_4_1_3_container label,
    span#input_5_1_3_container label {
      display: none;
    }

    span.apoiar {
      font-family: $secondary-font;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      text-align: left;
      text-transform: uppercase;
    }

    .sender-form-field {
      min-height: 365px;

      div {
        min-height: 365px;
      }
    }
  }
}


/* Newsletter */
section.newsletter {
  padding: 112px 0;

  .background {
    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      object-fit: cover;
    }
  }

  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    font-family: $secondary-font;
    display: block;
    margin-bottom: 22px;
    text-transform: uppercase;
  }

  ul.social-wrapper {
    padding-left: 0;
    display: inline-flex;
    margin-bottom: 54px;

    li {
      a {
        margin-right: 16px;
      }
    }
  }

  a.email {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: block;
    margin-bottom: 12px;
    color: $black;
  }

  .form-wrapper {
    display: block;
    float: left;
    width: 100%;

    h6 {
      margin-bottom: 36px;
      display: block;
    }
  }

  span#input_4_1_3_container label,
  span#input_5_1_3_container label {
    display: none;
  }
}


/* Patrocinadores */
section.patrocinadores {
  padding: 112px 0;

  .logos-wrapper {
    margin: 56px 0;

    &.no-padd {
      margin-top: 0;
    }

    .single-wrapper {
      margin-bottom: 32px;

      img {
        width: 100%;
      }
    }

    &.second {
      .single-wrapper {
        img {
          max-height: 5.75rem;
          width: auto;
          max-width: 8.75rem;
          text-align: center;
          margin: auto;
          display: block;
        }
      }
    }
  }

  h2 {
    color: $yellow;
  }

  span.mail-apoio p a {
    color: black;
    text-decoration: underline !important;
  }

  .swiper-pagination.swiper-pagination-bullets {
    margin-top: -12px;
    position: relative;

    span.swiper-pagination-bullet {
      opacity: 0.3;
      background: $yellow;
      width: 10px;
      height: 10px;

      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
}

/* Equipas */
section.equipas {
  padding: 112px 0 134px;

  .background {
    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }

  .image-wrapper {
    margin-top: 54px;
    width: 100%;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  h2 {
    color: $yellow;
  }

  span,
  span p {
    color: $white;
  }
}

/* Equipas */
section.apoiar {
  padding: 112px 0;
  border-top: 4px solid $yellow;
  border-bottom: 4px solid $yellow;

  .background {
    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      object-fit: cover;
      object-position: top;
    }
  }

  h2 {
    margin-bottom: 56px;
  }

  .card-wrapper {
    background: $dark-gray;
    height: 100%;
    padding: 46px 46px 40px;
    text-align: center;
    border-radius: 28px;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    transition: .3s;

    .image.wrapper {
      margin-bottom: 12px;
    }

    h4 {
      color: $yellow;
      font-size: 24px;
    }

    .line {
      text-align: center;
    }

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: $white;
      display: block;
      margin: 16px 0;

      &.name,
      &.position {
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: $yellow;
        margin: 0;
      }

      &.name {
        font-weight: 600;
        margin-top: 16px;
      }
    }
  }

  .imagem-apoio {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .imagem-apoio-hover {
    display: none;
  }
}

/* Menu */
.header.mob {
  display: none;
}

@media only screen and (min-width: 1023px) {
  section.apoiar .card-wrapper:hover {
    background: $yellow;

    h4,
    span {
      color: $black;
    }

    .line {
      svg line {
        stroke: $black;
      }
    }

    .imagem-apoio {
      display: none;
    }

    .imagem-apoio-hover {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

body.page-template.page-template-template-custom {
  background: $dark-gray;

  section.typ {
    margin-top: 56px;
  }

  h1,
  span.desc,
  span.desc p {
    color: $white;
  }

  .nav-primary,
  .socio-wrapper {
    display: none;
  }
}