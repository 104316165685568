@mixin btn-style() { //mixin normal for standard elements
  display: inline-block;
  padding: 1rem 1.5rem;
  border: 0px solid transparent;
  font-family: $primary-font;
  font-size: 20px;
  line-height: 1.2; 
  font-style: normal;
  font-weight: normal; 
  text-align: center;
  transition-duration: 0.3s;
  transition-property: background-color, color, border-color;
  user-select: none;
  vertical-align: middle;

  &:not(:disabled):not([aria-disabled='true']) {
    cursor: pointer;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

@mixin btn-style-modifier( //mixin for variations of elements, be creative
  $background-color: $primary-color,
  $background-color-hover: auto,
  $color: auto,
  $color-hover: auto,
  $border-width: 0px,
  $border-color: undefined,
  $border-color-hover: undefined,
  $border-radius: 0px,
  $background-color-lightness-threshold: 70%,
  $opacity-disabled: 0.7,
  $include-disabled-styles: true,
  $background-color-disabled: undefined,
  $border-color-disabled: undefined,
  $color-disabled: undefined,
  $include-focus-styles: true

  ) {
  @if $background-color-hover == auto {
    $background-color-hover: scale-color($background-color, $lightness: -20%);
  }

  $background-color-lightness: 0;
  $background-color-hover-lightness: 0;

  @if not($background-color == null) {
    $background-color-lightness: lightness($background-color);
  }

  @if $background-color == transparent {
    $background-color-lightness: $background-color-lightness-threshold + 1%;
  }

  @if not($background-color-hover == null) {
    $background-color-hover-lightness: lightness($background-color-hover);
  }

  background-color: $background-color;
  border: $border-width solid $border-color;
  border-radius: $border-radius;
  color: $color;


  &:hover {
    background-color: $background-color-hover;
    border-color: $border-color-hover;
    color: $color-hover;
  }

  @if $include-focus-styles {
    &:focus {
      outline: 2px dotted auto;
      outline-offset: 1px;
    }
  }


  @if $include-disabled-styles {
    &:disabled,
    &[aria-disabled='true'] {
      opacity: $opacity-disabled;
    }
  }
}

/** OBJECTIVE: 
**  Use inside button class under here to make all buttons!
**  Can use: <button> or if you want <a> tag.
**  Do simple name, like color name to buttons, and add '-border' to change for border style
*/

a.button,
button {
  @include btn-style();

  &.primary{
    @include btn-style-modifier(
      $color: $primary-color,
      $border-width: 2px,
      $border-color: $primary-color,
      $background-color: $white,
      $background-color-hover: $primary-color,
      //$color-hover: $white
      $border-radius: 2rem
      );
  }
  &.primary-border{
    @include btn-style-modifier(
      $color: $primary-color,
      $border-width: 2px,
      $border-color: $primary-color,
      $background-color: $white,
      $background-color-hover: $primary-color,
      //$color-hover: $white
      $border-radius: 2rem
      );
  }

  &.secondary{
    @include btn-style-modifier(
      $color: $primary-color,
      //$border-width: 2px,
      //$border-color: $brown,
      $background-color: $secondary-color,
      $background-color-hover: $primary-color,
      $color-hover: $white,
      $border-radius: 2rem
      );
  }

  &.light{
    @include btn-style-modifier(
      $color: $black,
      //$border-width: 2px,
      //$border-color: $brown,
      $background-color: $light-gray,
      $background-color-hover: $medium-gray,
      $color-hover: $white,
      $border-radius: 2rem
      );
  }
}

a.anchor{
  display: block;
  position: relative;
  visibility: hidden;
  top: -200px;
  float: left;
  width: 100%;
}
a.anchor2{
  display: block;
  position: relative;
  visibility: hidden;
  bottom: -300px;
  float: left;
  width: 100%;
}

a.button.gradient {
  background: linear-gradient(90deg, #1BA2CB 0%, #31BFA4 100%);
  border-radius: 160px;
  font-family: $primary-font;
  font-style: normal;
  font-weight: 900;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.01em;
  color: $bright;
  padding: 0.5rem 1.5rem;
  transition: none;
  &:hover {
    background: $blue-button;
  }
}


a.button.border {
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 32px;
  color: $white;
  padding: .5rem 1rem .25rem;
  font-style: normal;
  font-weight: 560;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.01em;
  display: inline-block;
  transition: .3s;
  & svg {
    margin-left: 0.5rem;
    margin-bottom: 6px;
    transition: .3s;
    & path {
     transition: .3s;
   }
 }
 &:hover {
  background: $white;
  color: $blue;
  & svg {
    path {
      fill: $blue;
    }
  }
}
&.black {
  color: $white;
  background: $blue-button;
  border: none;
  padding: .75rem 1.5rem;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  transition: none;
  &:hover {
    background: $gradient;
    border: none;
    color: $white;
  }
}
}


/* ------------- */

//SELECT
span.select2{
  padding: 0;
  border: 1px solid $light-gray;
  &.select2-container{
    width: 100%!important;
    background-color: $white;
    & .select2-selection--single{
      height: auto;
      border: none;
      & .select2-selection__rendered{
        font-size: 18px;
        line-height: 1.45;
        letter-spacing: -0.23px;
        color: $black;
        padding: 0.75rem 2.5rem;
      }
      
      & .select2-selection__arrow {
        height: 100%;
        & b{
          border-color: $black transparent transparent transparent;
        }
      }
    }
    &.select2-container--open{
      & .select2-selection--single .select2-selection__arrow b{
        border-color: transparent transparent $black transparent;
      }
    }
  }
}
//OPENED DROPDOWN
.select2-dropdown{
  border: 1px solid $light-gray;
  opacity: 0;
  transform: scale(0.75) translateY(-19px);
  transition: all 0.25s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.2s ease-out;
  &.opened{
    opacity: 1;
    transform: scale(1) translateY(0);
  }
  & .select2-results > .select2-results__options{
    scrollbar-width: thin;
    scrollbar-color: $medium-gray;
    &::-webkit-scrollbar{
      width: 10px;
    }
    &::-webkit-scrollbar-track{
      background: $white;
    }
    &::-webkit-scrollbar-thumb{
      background-color: $medium-gray;
      border-radius: 6px;
      border: 3px solid $white;   
    }
    & .select2-results__option{
      font-weight: normal;
      font-size: 18px;
      line-height: 1.45;
      color: $black;
      padding: 0.75rem 2.5rem;
      &.select2-results__option--highlighted[aria-selected], 
      &.select2-results__option--highlighted[data-selected]{
        background-color: $medium-gray;
        color: $primary-color;
      }
      &.select2-results__option--selected{
        background-color: $dark-gray;
        color: $white; 
      }
    }
  }
}

/* ABC */
a.button,
input#gform_submit_button_4,
input#gform_submit_button_5 {
  background: $yellow;
  color: $black;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.05em;
  font-family: 'Bebas Neue', cursive;
  padding: 12px 32px;
  border-radius: 50px;
  border: none;
  height: 40px;
  transition: .3s;
}

input#gform_submit_button_4,
input#gform_submit_button_5 {
  &:hover {
    background: $white !important;
    color: $black !important;
  }
}

section#contactos {
  input#gform_submit_button_4,
  input#gform_submit_button_5 {
    &:hover {
      background: $black !important;
      color: $white !important;
    }
  }
}

.gform_wrapper.gravity-theme .gform_footer, .gform_wrapper.gravity-theme .gform_page_footer {
  margin: 16px 0 !important;
}


a.button.social {
  border-radius: 20px;
  padding: 15px 36px;
  height: 50px;
}

a.button:hover {
  background: $black;
  color: $white;
}

a.button.social:hover,
a.button.socio:hover {
  background: $white;
  color: $black;
}



